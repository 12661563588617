<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <div>
          <form-wizard
            :subtitle="null"
            :title="null"
            back-button-text="Previous"
            class="mb-3"
            color="#7367F0"
            finish-button-text="Submit"
            shape="square"
            @on-complete="formSubmitted"
          >

            <!-- Basic Info tab -->
            <tab-content
              :before-change="validationForm"
              title="Basic Information"
            >
              <validation-observer
                ref="incubatorBasicInfoRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Incubator Name *"
                      label-for="incubator"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Incubator Name"
                        rules="required"
                      >
                        <b-form-input
                          id="incubator"
                          v-model="incubator.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Incubator Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Designation *"
                      label-for="designation"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Designation"
                        rules="required"
                      >
                        <b-form-input
                          id="designation"
                          v-model="incubator.designation"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Your Designation"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Description"
                      label-for="description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="incubator.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Incubator Description"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Official Email *"
                      label-for="official_email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Official Email"
                        rules="required"
                      >
                        <b-form-input
                          id="official_email"
                          v-model="incubator.official_email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="hello@yourbusiness.com"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Phone Number *"
                      label-for="url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="required"
                      >
                        <b-form-input
                          id="official_phone"
                          v-model="incubator.official_phone"
                          :state="errors.length > 0 ? false:null"
                          placeholder="+91-1234567890"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Website"
                      label-for="url"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Website"
                      >
                        <b-form-input
                          id="url"
                          v-model="incubator.url"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Incubator Website URL"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Industry"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Industry *"
                        label-for="industry"
                      >
                        <v-select
                          id="industry"
                          v-model="incubator.industry"
                          :options="industries"
                          placeholder="Select Industry"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Lifecycle Stage"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Lifecycle Stage *"
                        label-for="startup_stage"
                      >
                        <v-select
                          id="startup_stage"
                          v-model="incubator.startup_stage"
                          :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                          placeholder="Select Lifecycle Stage"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="City *"
                      label-for="city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-input
                          id="city"
                          v-model="incubator.city"
                          :state="errors.length > 0 ? false:null"
                          placeholder="City"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="State"
                      label-for="state"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                      >
                        <b-form-input
                          id="state"
                          v-model="incubator.state"
                          :state="errors.length > 0 ? false:null"
                          placeholder="State"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

          </form-wizard>

        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      mutationLoading: false,
      incubator: {
        title: null,
        designation: null,
        description: null,
        official_email: null,
        official_phone: null,
        url: null,
        industry: null,
        startup_stage: null,
        city: null,
        state: null,
      },
      industries: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      required,
      email,
    }
  },
  computed: {
    orgAssocId() {
      return getUserData().associatedOrgDetails.filter(org => org.role === 'superadmin')[0].id
    },
  },
  methods: {
    formSubmitted() {
      this.addIncubator()
      this.mutationLoading = true
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.incubatorBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      const incubatorDetails = { ...this.incubator }
      delete incubatorDetails.assoc_id
      delete incubatorDetails.designation
      const object = {
        users_organizationtable: {
          data: {
            ...incubatorDetails,
          },
          on_conflict: {
            constraint: 'users_organizationtable_pkey',
            update_columns: ['title',
              'description',
              'official_email',
              'official_phone',
              'url',
              'industry',
              'startup_stage',
              'city',
              'state'],
          },
        },
        designation: this.incubator.designation,
        user_id: getUserData().id,
      }
      if (this.incubator.assoc_id) object.id = this.incubator.assoc_id
      delete object.assoc_id
      return object
    },
    addIncubator() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation update_incubator_details($object: users_associationtable_insert_input! ) {
          insert_users_associationtable_one(on_conflict: {update_columns: designation, constraint: users_associationtable_pkey}, object: $object) {
            id
          }
        }`,
        variables: {
          object: this.mutationObject(),
        },
        update: (store, { data: { insert_users_associationtable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_users_associationtable_one.id ? 'Incubator Updated' : 'There was an Error',
              icon: 'EditIcon',
              variant: insert_users_associationtable_one.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.incubator.refetch()
          this.mutationLoading = false
          this.incubator = {
            title: null,
            designation: null,
            description: null,
            official_email: null,
            official_phone: null,
            url: null,
            industry: null,
            startup_stage: null,
            city: null,
            state: null,
          }
        },
      })
      return this.error
    },
  },
  apollo: {
    incubator: {
      query() {
        return gql`{
          users_associationtable_by_pk(id: ${this.orgAssocId}) {
            id
            designation
            users_organizationtable {
              id
              title
              description
              official_email
              official_phone
              url
              industry
              startup_stage
              city
              state
            }
          }
        }`
      },
      update: data => ({
        assoc_id: data.users_associationtable_by_pk.id,
        designation: data.users_associationtable_by_pk.designation,
        ...data.users_associationtable_by_pk.users_organizationtable,
      }),
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
